'use client';

import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';

import { UserApps } from './UserAppsTable.type';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { MoreHorizontal } from 'lucide-react';
import { FiCopy } from 'react-icons/fi';
import { truncate } from '@/lib/utils.ts';
import { Dispatch, SetStateAction } from 'react';

export const UserAppsTableColumns = (
    onOpenEditApp: (app: UserApps) => void,
    onDeleteApp: (appId: number) => void,
    setShowCopyPopup: Dispatch<SetStateAction<boolean>>,
): ColumnDef<UserApps>[] => [
    {
        accessorKey: 'id',
        header: 'ID',
    },
    {
        accessorKey: 'name',
        header: 'NAME',
    },
    {
        accessorKey: 'description',
        header: 'DESCRIPTION',
    },
    {
        accessorKey: 'project_number',
        header: 'PROJECT NUMBER',
    },
    {
        accessorKey: 'access_token',
        header: 'ACCESS TOKEN',
        cell: ({ row }) => {
            const action = row.original;
            const copyToClipboard = (text: string, setCopyPopUp: Dispatch<SetStateAction<boolean>>) => {
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        setCopyPopUp(true);
                        setTimeout(() => setCopyPopUp(false), 1500);
                    })
                    .catch((err) => {
                        console.error('Could not copy text: ', err);
                    });
            };

            return (
                <div className="p-0 w-full">
                    <span>{truncate(action.access_token, 10)}</span>
                    <button
                        onClick={() => copyToClipboard(action.access_token, setShowCopyPopup)}
                        title="Click to copy"
                    >
                        <FiCopy />
                    </button>
                </div>
            );
        },
    },
    {
        id: 'actions',
        header: 'ACTIONS',
        cell: ({ row }) => {
            const action = row.original;

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-4 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuItem onClick={() => onOpenEditApp(action)}>Edit</DropdownMenuItem>
                        <DropdownMenuItem onClick={() => onDeleteApp(action.id)}>Delete</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        },
    },
];
