import { UserAppsTableColumns } from './UserAppsTableColumns';
import { DataTable } from '@/ui/modules/DataTable/DataTable';
import { UserApps } from './UserAppsTable.type';
import { Dispatch, SetStateAction } from 'react';
interface UserAppsTableProps {
    data: UserApps[];
    onOpenEditApp: (app: UserApps) => void;
    onDeleteApp: (appId: number) => void;
    setShowCopyPopup: Dispatch<SetStateAction<boolean>>;
    showPagination?: boolean;
}

export const UserAppsTable: React.FC<UserAppsTableProps> = ({
    data,
    onOpenEditApp,
    onDeleteApp,
    setShowCopyPopup,
    showPagination = false,
}) => {
    return (
        <div>
            <DataTable
                columns={UserAppsTableColumns(onOpenEditApp, onDeleteApp, setShowCopyPopup)}
                data={data}
                showPagination={showPagination}
            />
        </div>
    );
};
